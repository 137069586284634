import polyglotI18nProvider from 'ra-i18n-polyglot';

import { englishMessages } from '../i18n/en';
import { hebrewMessages } from '../i18n/he';

export type Lang = 'he' | 'en';

export const translators: { [key: string]: any } = {
	'en': englishMessages,
	'he': hebrewMessages
};

export const i18nProvider = polyglotI18nProvider(locale => {
		return translators[locale];
	},
	'he'
);
