import _get from 'lodash-es/get';
import * as XLSX from 'xlsx';

export interface ExportField {
	key: string;
	header: string;
}

export const generateExporter = (fileName: string, fields: ExportField[], mapper?: Function) => entities => {
	if (mapper) {
		entities = entities.map(mapper);
	}

	const entitiesForExport = entities.map(entity => {
		const buildExportEntity = {};

		if (fields?.length) {
			fields.forEach(field => {
				buildExportEntity[field.header] = _get(entity, field.key);
			});
		}

		return buildExportEntity;
	});

	const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(entitiesForExport);
	const wb: XLSX.WorkBook = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(wb, ws, fileName);

	XLSX.writeFile(wb, `${fileName}.xlsx`);
};
