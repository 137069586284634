import React from 'react';
import { Pagination as RAPagination, PaginationProps } from 'react-admin';

const PAGINATION_OPTIONS = [10, 20, 50];

const Pagination = (props: PaginationProps) => (
	<RAPagination rowsPerPageOptions={PAGINATION_OPTIONS} {...props} />
);

export default Pagination;
