import * as React from 'react';
import { createElement, FC, ReactNode } from 'react';
import { Box, Card, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';
import { createTheme } from '@mui/system';

interface Props {
	icon: FC<any>;
	to: string;
	title?: string;
	subtitle?: string | number;
	children?: ReactNode;
}

const theme = createTheme();

const classes = {
	card: {
		minHeight: 52,
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		'& a': {
			textDecoration: 'none',
			color: 'inherit'
		}
	},
	main: {
		overflow: 'inherit',
		padding: '16px',
		background: `url(${
			theme.palette.type === 'dark' ? cartoucheDark : cartouche
		}) no-repeat`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& .icon': {
			color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440'
		}
	},
	title: {}
};

const CardWithIcon = (props: Props) => {
	const { icon, title, subtitle, to, children } = props;
	return (
		<Card sx={classes.card}>
			<Link to={to}>
				<Box sx={classes.main}>
					<Box textAlign="right">
						<Typography
							sx={classes.title}
							color="textSecondary"
						>
							{title}
						</Typography>
						<Typography variant="h5" component="h2">
							{subtitle || ' '}
						</Typography>
					</Box>
					<Box width="3em" className="icon">
						{createElement(icon, { fontSize: 'large' })}
					</Box>
				</Box>
			</Link>
			{children && <Divider/>}
			{children}
		</Card>
	);
};

export default CardWithIcon;
