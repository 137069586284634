export const serialize = (obj: any, prefix?: string): string => {
	const str: string[] = [];
	let p;

	for (p in obj) {
		if (obj.hasOwnProperty(p)) {
			var k = prefix ? prefix + '[' + p + ']' : p,
				v = obj[p];
			str.push((v !== null && typeof v === 'object' && !(v instanceof Date)) ?
				serialize(v, k) :
				encodeURIComponent(k) + '=' + encodeURIComponent((v instanceof Date) ? v.toISOString() : v));
		}
	}

	return str.join('&');
};
